import React from "react"
import { Button } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import * as styles from "../styles/pages/hire.module.css"
import { useStaticQuery } from "gatsby"

const Hire = () => {
  const data = useStaticQuery(graphql`
    query HirePage {
      prismicHirepage {
        data {
          banner {
            fluid {
              src
            }
          }
          career_desc {
            text
          }
          career_title {
            text
          }
          hire_us_desc {
            text
          }
          hire_us_title {
            text
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Hire us" />
      <div className={styles.container}>
        <div className={styles.bannerOverlay}></div>
        <Img
          className={styles.hireBannerImg + " w-100"}
          fluid={{
            ...data.prismicHirepage.data.banner.fluid,
            sizes: "",
          }}
          alt="slide"
        />
        <div className={styles.txtContainer}>
          <div>
            <h1>{data.prismicHirepage.data.hire_us_title.text}</h1>
            <p>{data.prismicHirepage.data.hire_us_desc.text}</p>
            <Link to="/get-quote">
              <Button className={styles.hireBtn}>Get a quote</Button>
            </Link>
          </div>
          <div>
            <h1>{data.prismicHirepage.data.career_title.text}</h1>
            <p>{data.prismicHirepage.data.career_desc.text}</p>
            <Link to="/career">
              <Button className={styles.hireBtn}>Work with us</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Hire
